import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { connect } from 'react-redux'
import Grid from '@mui/material/Grid'
import ActivityStatisticsCards from './ActivityStatisticsCards'
import Paper from '@mui/material/Paper'
import moment from 'moment'
import SymptomScoresChart from '@components/SymptomScoresChart'
import { CalendarHeatMap, SymptomScoresLifetime } from '@components'
import { navigate } from 'gatsby'
import { paths } from 'routes'

const PREFIX = 'HistoryContent'

const classes = {
	root: `${PREFIX}-root`,
	roleContainer: `${PREFIX}-roleContainer`,
	separate: `${PREFIX}-separate`,
}

const Root = styled('div')(({ theme }) => ({
	[`&.${classes.root}`]: {
		padding: theme.spacing(4),
	},

	[`& .${classes.roleContainer}`]: {
		paddingTop: theme.spacing(4),
	},

	[`& .${classes.separate}`]: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
}))

const HistoryContent = ({ auth, symptomScore, summary }) => {
	const summaryLoading = summary.loading
	const symptomScoreLoading = symptomScore.loading.read
	const startDay = Object.keys(symptomScore.daysScores).sort()[0]

	return (
		<Root className={classes.root}>
			<Grid container spacing={4} className={classes.separate}>
				<Grid item xs={12}>
					{/* <ActivityStatisticsCards /> */}

					<Paper>
						<CalendarHeatMap
							loading={summaryLoading}
							daysEvents={summary.daysEvents}
							onClick={(event) => {
								navigate(`${paths.diary}?day=${event.data[0]}`)
							}}
						/>
					</Paper>
				</Grid>
			</Grid>
			<Grid container spacing={4} className={classes.separate}>
				<Grid item sm={12} xs={12}>
					<Paper>
						{/* <SymptomScoresLifetime
							loading={symptomScoreLoading}
							symptomScores={symptomScore.daysScores}
							onClick={(event) => {
								navigate(`${paths.diary}?day=${event.data[0]}`)
							}}
						/> */}
						<SymptomScoresChart
							scores={symptomScore.daysScores}
							range={{ start: startDay, end: moment().format('YYYY-MM-DD') }}
						/>
					</Paper>
				</Grid>
			</Grid>
		</Root>
	)
}

HistoryContent.propTypes = {}

const mapStateToProps = ({ auth, symptomScore, summary }) => {
	return {
		auth,
		symptomScore,
		summary,
	}
}

export default connect(mapStateToProps, actions)(HistoryContent)
