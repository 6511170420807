import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as actions from 'actions'
import { connect } from 'react-redux'
import moment from 'moment'
import Paper from '@mui/material/Paper'

import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import LinearProgress from '@mui/material/LinearProgress'

const PREFIX = 'ActivityStatisticsCards'

const classes = {
	root: `${PREFIX}-root`,
}

const StyledPaper = styled(Paper)(({ theme }) => ({
	[`&.${classes.root}`]: {
		width: '100%',
		overflowX: 'auto',
	},
}))

const AccountProfile = (props) => {
	const { auth, className, ...rest } = props

	return <StyledPaper className={classes.root}></StyledPaper>
}

AccountProfile.propTypes = {
	className: PropTypes.string,
}

const mapStateToProps = ({ auth }) => {
	return {
		auth,
	}
}

export default connect(mapStateToProps, actions)(AccountProfile)
